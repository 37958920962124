<template>
  <section class="content-header">
    <h1>
      <Icon type="md-arrow-dropright-circle" />
      {{ pageHeader }}
      <small v-if="pageDescr">/ {{ pageDescr }}</small>
    </h1>
  </section>
</template>

<style scoped>
.content-header {
  padding: 0;
  margin: 20px 0 20px 20px;
}
.content-header h1 {
  font-size: 24px;
  font-weight: 500;
  color: #268b97;
}
.content-header h1 small {
  font-size: 14px;
  font-weight: 300;
  color: #89a6ac;
  margin-left: 10px;
}
</style>

<script>
export default {
  name: 'page-header',
  computed: {
    pageHeader () {
      return this.$store.getters.pageHeader
    },
    pageDescr () {
      return this.$store.getters.pageDescr
    }
  }
}
</script>
