<template>
  <div class="monitor">
    <pageHeaderView></pageHeaderView>
    <div class="monitor-wrapper">
      <div class="navigator">
        <button @click="fullScreen=!fullScreen">
          <Icon type="md-expand" v-if="!fullScreen" />
          <Icon type="md-contract" v-else />
        </button>
      </div>
      <div id="monitorMain" :class="{fullscreen:fullScreen}"></div>
    </div>
  </div>
</template>

<script>
import pageHeaderView from './public/PageHeader'
import cities from './../assets/cities'
import leftPad from 'leftpad'
var echarts = require('echarts')
require('echarts/extension/bmap/bmap')

export default {
  name: 'monitor',
  props: {
    fullScreenMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    pageHeaderView,
    // baiduMap
  },
  data () {
    return {
      fullScreen: false,
      dataRaw: [],
      myChart: null,
      mapCenter: [104.114129, 37.550339],
      mapZoom: 5,
      bmapInstance: null
    }
  },
  mounted () {
    this.$store.dispatch('setPageStore', {
      pageHeader: '监控中心',
      pageDescr: '查看辖区项目'
    })
    this.getData()
  },
  watch: {
    'fullScreenMode': function (newVal) {
      this.fullScreen = newVal
    },
    'fullScreen': function () {
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById('monitorMain'))
        }
        this.myChart.resize()
      })
    },
    'dataRaw': function (newVal) {
      this.drawMap(newVal)
    }
  },
  methods: {
    checkRolePermitted (roles) {
      if (!this.$store.getters.permission || !this.$store.getters.permissions.length) {
        return false
      }
      if (this.$store.getters.permission % 2 === 0) {
        // 后台-管理员
        return true
      }
      let userRoles = []
      this.$store.getters.permissions.forEach((p) => {
        userRoles.push(this.$common.permissions[p.toString()])
      })
      if (roles instanceof Array) {
        let inside = false
        roles.forEach((r) => {
          if (userRoles.indexOf(r) > -1) {
            inside = true
          }
        })
        return inside
      }
      return userRoles.indexOf(roles) > -1
    },
    convertData (data) {
      let vmInstance = this
      var res = []
      for (var i = 0; i < data.length; i++) {
        var geoCoord = vmInstance.geoCoordMap[data[i].name]
        if (geoCoord) {
          res.push({
            name: data[i].name,
            value: geoCoord.concat(data[i].value)
          })
        }
      }
      return res
    },
    getData () {
      this.$Spin.show()
      this.$Api.Project.listAllProjectsWithAlarmAndComplaint().then((respBody) => {
        this.$Spin.hide()
        this.dataRaw = respBody.Projects
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取项目列表失败!',
          desc: err.message
        })
      })
    },
    getOptions () {
      let series = ['正常', '预警']
      if (this.checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])) {
        series.push('投诉')
      }
      let cfg = {
        backgroundColor: 'transparent', // #404a59
        title: {
          text: '农民工工资支付风险监控',
          subtext: '数据来源：思创网联',
          left: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item',
          enterable: true,
          formatter: (params) => {
            return this.tooltipFormatter(params)
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              readOnly: false
            },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          orient: 'vertical',
          y: 'top',
          x: 'left',
          data: series,
          textStyle: {
            color: '#fff'
          }
        },
        bmap: {
          center: this.mapCenter,
          zoom: this.mapZoom,
          roam: true,
          mapStyle: {
            styleJson: [
              {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': {
                  'color': '#044161'
                }
              },
              {
                'featureType': 'land',
                'elementType': 'all',
                'stylers': {
                  'color': '#004981'
                }
              },
              {
                'featureType': 'boundary',
                'elementType': 'geometry',
                'stylers': {
                  'color': '#064f85'
                }
              },
              {
                'featureType': 'railway',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'highway',
                'elementType': 'geometry',
                'stylers': {
                  'color': '#004981'
                }
              },
              {
                'featureType': 'highway',
                'elementType': 'geometry.fill',
                'stylers': {
                  'color': '#005b96',
                  'lightness': 1
                }
              },
              {
                'featureType': 'highway',
                'elementType': 'labels',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'arterial',
                'elementType': 'geometry',
                'stylers': {
                  'color': '#004981'
                }
              },
              {
                'featureType': 'arterial',
                'elementType': 'geometry.fill',
                'stylers': {
                  'color': '#00508b'
                }
              },
              {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'green',
                'elementType': 'all',
                'stylers': {
                  'color': '#056197',
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'subway',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'manmade',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'local',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'arterial',
                'elementType': 'labels',
                'stylers': {
                  'visibility': 'off'
                }
              },
              {
                'featureType': 'boundary',
                'elementType': 'geometry.fill',
                'stylers': {
                  'color': '#029fd4'
                }
              },
              {
                'featureType': 'building',
                'elementType': 'all',
                'stylers': {
                  'color': '#1a5787'
                }
              },
              {
                'featureType': 'label',
                'elementType': 'all',
                'stylers': {
                  'visibility': 'off'
                }
              }
            ]
          }
        },
        series: [
        ]
      }
      return cfg
    },
    drawMap (projects) {
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById('monitorMain'))
      }
      console.log(this.myChart)
      let normalData = []
      let alarmData = []
      let complaintData = []
      projects.filter((item) => {
        return !item.AlarmCount && !item.ComplaintCount
      }).forEach((item) => {
        normalData.push({
          name: item.Name,
          value: [
            item.Longitude / 1000000,
            item.Latitude / 1000000,
            '状态正常',
            item
          ]
        })
      })
      projects.filter((item) => {
        return item.AlarmCount
      }).forEach((item) => {
        alarmData.push({
          name: item.Name,
          value: [
            item.Longitude / 1000000,
            item.Latitude / 1000000,
            '发生预警',
            item
          ]
        })
      })
      projects.filter((item) => {
        return !item.AlarmCount && item.ComplaintCount
      }).forEach((item) => {
        complaintData.push({
          name: item.Name,
          value: [
            item.Longitude / 1000000,
            item.Latitude / 1000000,
            '发生投诉',
            item
          ]
        })
      })
      let options = this.getOptions()
      options.series = [
        {
          name: '正常',
          type: 'effectScatter',
          coordinateSystem: 'bmap',
          data: normalData,
          symbolSize: function () {
            return 10
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: true,
              formatter: '{b}',
              position: 'top',
              color: '#fff',
              fontSize: 16
            }
          },
          itemStyle: {
            normal: {
              color: '#7cfc00'
            }
          }
        },
        {
          name: '预警',
          type: 'effectScatter',
          coordinateSystem: 'bmap',
          data: alarmData,
          symbolSize: function () {
            return 16
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: true,
              formatter: '{b}',
              position: 'top',
              color: '#fff',
              fontSize: 16
            }
          },
          itemStyle: {
            normal: {
              color: '#ff4500'
            }
          }
        }
      ]
      if (this.checkRolePermitted(['后台-管理员','后台-内容维护人员','担保公司-管理员','担保公司-业务员','担保公司-风控','担保公司-运营'])) {
        options.series.push({
          name: '投诉',
          type: 'effectScatter',
          coordinateSystem: 'bmap',
          data: complaintData,
          symbolSize: function () {
            return 14
          },
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: true,
              formatter: '{b}',
              position: 'top',
              color: '#fff',
              fontSize: 16
            }
          },
          itemStyle: {
            normal: {
              color: '#ffcc00'
            }
          }
        })
      }
      console.log(options)
      this.myChart.setOption(options)
      let bmap = this.myChart.getModel().getComponent('bmap').getBMap()
      this.bmapInstance = bmap
    },
    getArea (row) {
      if (row.Province) {
        let county = this.getCityName(leftPad(row.Province, 2) + leftPad(row.Municipality, 2) + leftPad(row.County, 2))
        let municipality = this.getCityName(leftPad(row.Province, 2) + leftPad(row.Municipality, 2) + '00')
        let province = this.getCityName(leftPad(row.Province, 2) + '0000')
        return province + '/' + municipality + '/' + county
      } else {
        return '-'
      }
    },
    getCityName (code) {
      try {
        return cities.filter((item) => {
          return item.code === code
        })[0].city
      } catch (e) {
        return 'unkown'
      }
    },
    tooltipFormatter (params) {
      // console.log(params)
      let tooltip = ''
      let project = params.data.value[3]
      tooltip += `<p><b><a href="/#/projects/view/${project.Tid}">${params.data.name}</a></b></p>`
      let status = params.seriesName
      // let statusColor = ''
      // switch (status) {
      //   case '正常':
      //     statusColor = '#7cfc00'
      //     break
      //   case '预警':
      //     statusColor = '#ff4500'
      //     break
      //   case '投诉':
      //     statusColor = '#fc0'
      //     break
      // }
      tooltip += `<p style="display:inline-block;padding:0 5px;border-radius:5px;"><b>${params.marker}&nbsp;${status}</b></p>`
      tooltip += `<p>${this.getArea(project)}</p>`
      tooltip += `<p>${project.Address}</p>`
      tooltip += `<p>业主：${project.Companies[0].Name}</p>`
      tooltip += `<p>总包：${project.Companies[1].Name}</p>`
      tooltip += `<div class="padding-top-10"></div>`
      tooltip += `<p><b><a class="ivu-btn ivu-btn-long ivu-btn-primary" href="/#/projects/view/${project.Tid}">查看项目</a></b></p>`
      return tooltip
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.monitor-wrapper, #monitorMain {
  width: 100%;
  height: 600px;
  position: relative !important;
  &.fullscreen {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
  }
}
.monitor-wrapper {
  position: relative;
}
.monitor-wrapper .navigator {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 100001;
}
</style>
